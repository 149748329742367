import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ButtonComponent = ({
  isLoading,
  onClick,
  onKeyDown,
  btnContent,
  type,
  tabIndex,
  className,
  children,
  ariaLabel,
  icon,
  disabled,
  hidden,
}) => {
  return (
    <section>
      <button
        onClick={onClick}
        onKeyDown={onKeyDown}
        className={`flex items-center px-6 py-2 text-center font-medium text-white border border-transparent rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 ${className} mx-auto ${hidden} ${disabled}`}
        disabled={isLoading}
        type={type}
        tabIndex={tabIndex}
        aria-label={ariaLabel}
      >
        <FontAwesomeIcon icon={icon} className="pr-3" />
        {isLoading ? "Loading..." : children ? children : btnContent}
      </button>
    </section>
  );
};

ButtonComponent.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  btnContent: PropTypes.string,
  type: PropTypes.string,
  tabIndex: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  ariaLabel: PropTypes.string,
};

export default ButtonComponent;
