import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./intro.css";
import Questions from "./pages/questions/Questions";
import PageNotFound from "./pages/PageNotFound";
import Results from "./pages/results/results";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fas, far, fab);

function App() {
  return (
    <>
      <Routes>
        <Route exact={true} path="/" element={<Questions />} />
        <Route exact={true} path="/*" element={<PageNotFound />} />
        <Route path="/results" element={<Results />} />
      </Routes>
    </>
  );
}

export default App;
