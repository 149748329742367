import React from 'react';

export default function PageNotFound() {
    return (
        <div className='xl:px-12 content flex flex-col min-h-screen justify-between'>
            <div>
                <center className="pt-24">
                    <div className="tracking-widest mt-4">
                        <span className="text-gray-500 text-6xl block"><span>4  0  4</span></span>
                        <span className="text-gray-500 text-xl">Désolé, cette page n'existe pas !!</span>
                    </div>
                </center>
                <center className="mt-6">
                    <a href="/" className="text-gray-500 font-mono text-xl bg-gray-200 p-3 rounded-md hover:shadow-md">Page d'accueil </a>
                </center>
            </div>
        </div>
    )
}