import React, { useMemo } from "react";
import PropTypes from "prop-types";
import "./scrollbar.css";
import ButtonComponent from "./ButtonComponent";

function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}

const QCMComponent = React.memo(({ reponses, handleAnswers, handleSubmit }) => {
  const handleKeyDown = (e, dataItem) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.control.checked = !e.target.control.checked;
      handleAnswers(e, dataItem);
    }
  };

  const mappedResponses = useMemo(() => {
    return Object.keys(reponses).map((key) => reponses[key]);
  }, [reponses]);

  return (
    <div className="content flex flex-col h-[600px]">
      <div className="overflow-y-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-400 scrollbar-track-gray-300">
        <form>
          <div className="grid grid-cols-2 gap-x-4 gap-y-4 rounded-large">
            {mappedResponses.map((group) =>
              group.map((dataItem) => (
                <div key={dataItem.id} id="questions" className="flex">
                  <input
                    onChange={(e) => handleAnswers(e, dataItem)}
                    className="hidden"
                    name="rGroup"
                    type="checkbox"
                    value={dataItem.description}
                    id={"r" + dataItem.id}
                  />
                  {isValidUrl(dataItem.description) ? (
                    <label
                      htmlFor={"r" + dataItem.id}
                      className="cursor-pointer"
                      tabIndex="0"
                      onKeyDown={(e) => handleKeyDown(e, dataItem)}
                    >
                      <img
                        draggable="false"
                        src={dataItem.description}
                        alt={dataItem.description}
                        className="rounded-large"
                      />
                    </label>
                  ) : (
                    <label
                      className="text-sm md:text-xl lg:text-3xl cursor-pointer"
                      htmlFor={"r" + dataItem.id}
                      tabIndex="0"
                      onKeyDown={(e) => handleKeyDown(e, dataItem)}
                    >
                      {dataItem.description}
                    </label>
                  )}
                </div>
              ))
            )}
          </div>
        </form>
      </div>
      <div className="text-center text-2xl mt-8 py-2">
        <ButtonComponent
          type="submit"
          onClick={handleSubmit}
          btnContent={"Suivant"}
          tabIndex="0"
          icon="fa-solid fa-circle-chevron-right"
          className="inline-flex items-center py-2 px-3 font-medium text-center text-white bg-[#E44424] rounded-full focus:ring-4 focus:outline-none focus:ring-blue-300"
        />
      </div>
    </div>
  );
});

QCMComponent.propTypes = {
  reponses: PropTypes.array.isRequired,
  handleAnswers: PropTypes.func.isRequired,
};

export default QCMComponent;
