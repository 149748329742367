import React, { useState, useEffect } from "react";
import axios from "axios";
// import Adsense from "../Adsense";
import { useSearchParams } from "react-router-dom";
import HeaderComponent from "../questions/HeaderComponent";
import NextButtonComponent from "../questions/ButtonComponent";
import DemoComponent from "../questions/DemoComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

export default function Results(props) {
  const [results, setResults] = useState([]);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const navigate = useNavigate();
  const handleSubmit = async () => {
    navigate("/");
  };

  useEffect(() => {
    (async function () {
      const data = (
        await axios.get(`${process.env.REACT_APP_API_URL}results/${token}`, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        })
      ).data;
      setResults(data.results);
    })();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="flex justify-center items-center h-screen">
      <section className="md:px-12 content flex flex-col justify-between bg-[#F1F0EC] max-w-[768px] w-[768px] max-h-[900px] h-[900px]">
        <HeaderComponent
          question={"Merci, voici les résultats basés sur vos réponses."}
          imageURL={null}
          icon="fa-solid fa-list-ul"
          iconclasscame="text-white rounded-full text-6xl mx-6"
        />
        <div className="justify-center overflow-y-auto">
          <div className="items-center md:justify-start rounded-xl my-3 w-3/4 mx-auto">
            {results.length > 0 ? (
              results.map((a) => <Resultats key={a.id} {...a} />)
            ) : (
              <div className="text-center text-2xl font-bold tracking-tight text-gray-900">
                Aucun résultat
              </div>
            )}
          </div>
        </div>
        <div className="mb-6 text-center mt-16">
          <NextButtonComponent
            handleSubmit={handleSubmit}
            btnContent={"Recommencer"}
            onKeyDown={handleKeyDown}
            tabIndex="0"
            onClick={handleSubmit}
            icon="fa-solid fa-circle-chevron-right"
            className="inline-flex items-center mt-2 py-2 px-3 font-medium text-center text-white bg-[#E44424] rounded-full focus:ring-4 focus:outline-none focus:ring-blue-300"
          />
        </div>
      </section>

      <div className="flex-1 flex justify-center items-center !max-w-screen-md !w-screen-md">
        <DemoComponent />
      </div>
    </div>
  );
}

function Resultats(props) {
  return (
    <div className="flex rounded-xl overflow-hidden my-12 shadow-lg ">
      <img
        className="rounded-l-lg w-1/2 object-cover"
        src={props.image_id}
        alt={props.title + props.description}
      />
      <div className="flex flex-col justify-between px-6 py-4 w-2/3">
        <div>
          <a href={props.url}>
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
              {props.title}
            </h5>
          </a>
          <p className="font-normal text-gray-700 dark:text-gray-400">
            {props.description}
          </p>
        </div>
        <div className="text-center mt-auto">
          <a
            href={props.url}
            className="inline-flex items-center mt-2 py-2 px-3 text-sm font-medium text-center text-white bg-[#E44424] rounded-full focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            <FontAwesomeIcon
              icon="fa-solid fa-circle-chevron-right"
              className="pr-3"
            />
            Configurez le votre
          </a>
        </div>
      </div>
    </div>
  );
}
