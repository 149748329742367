import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../notes.css";
import QCMComponent from "./QCMComponent";
import StandardComponent from "./StandardComponent";
import HeaderComponent from "./HeaderComponent";
import ButtonComponent from "./ButtonComponent";
import DemoComponent from "./DemoComponent";

var questionsHistory = [];

function Questions(props) {
  const [question, setQuestion] = useState("Êtes vous prêt à commencer ?");
  const [idQuestions, setidQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [reponses, setReponses] = useState([]);
  const [type, setType] = useState("");
  const [answers, setAnswers] = useState([]);
  const [idAnswers, setidAnswers] = useState([]);
  const [btnContent, setBtnContent] = useState("Commencer");
  const [isSuperQuestion, setIsSuperQuestion] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [tags, setTags] = useState([]);
  const idMaster = useState("ce13e8f8-3428-4c31-baf6-ad254cca3bc7"); //lol
  //const idMaster = useState('5e3cc6db-b28d-4c5d-a455-8ccbaf0b2b78'); //ubni

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);

    questionsHistory.push({ type: type, question: question, answers: answers });
    const data = {
      questionsReponses: questionsHistory,
      idAnswers: idAnswers,
      idQuestions: idQuestions,
      isSuperQuestion: isSuperQuestion,
      idMaster: idMaster,
    };

    await axios
      .post(`${process.env.REACT_APP_API_URL}sendrequest`, data, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      })
      .then((res) => {
        if (res.data.token) {
          navigate("/results?token=" + res.data.token);
        } else {
          setBtnContent("Suivant");
          setTags(res.data.newQuestion.tags);
          setImageURL(res.data.newQuestion.image_url);
          setType(res.data.newQuestion.libelle);
          setQuestion(res.data.newQuestion.description);
          setQuestions([...questions, res.data.newQuestion]);
          setReponses([res.data.answers]);
          setIsSuperQuestion([
            ...isSuperQuestion,
            res.data.newQuestion.isSuperQuestion,
          ]);
          setAnswers([]);
          setidQuestions([...idQuestions, res.data.newQuestion.idQuestion]);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  function handleAnswers(e, dataItem) {
    setidAnswers([...idAnswers, dataItem.idAnswer]);
    setAnswers([...answers, [e.target.value]]);
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="content flex-col bg-[#F1F0EC] max-w-[768px] w-[768px] max-h-[900px] h-[900px]">
        <div className="mx-8">
          <HeaderComponent
            question={question}
            imageURL={imageURL}
            iconclasscame="bg-white rounded-full text-[#E44424] text-6xl mx-6"
            icon="fa-regular fa-circle-question"
          />
        </div>
        {(() => {
          switch (type) {
            // QCM
            case "QCM":
              return (
                <div className="text-2xl content w-2/3 mx-auto">
                  <QCMComponent
                    reponses={reponses}
                    handleAnswers={handleAnswers}
                    handleSubmit={handleSubmit}
                  />
                </div>
              );

            // Standard
            case "STANDARD":
              return (
                <div className="flex">
                  <section className="text-2xl content mx-auto">
                    <StandardComponent
                      reponses={reponses}
                      handleAnswers={handleAnswers}
                      handleSubmit={handleSubmit}
                    />
                  </section>
                </div>
              );

            default:
              return (
                <section className="text-2xl content w-1/2 mx-auto py-6">
                  <div className="mb-20">
                    <p>
                      Le questionnaire suivant est une série de questions visant
                      à mieux comprendre vos préférences en matière
                      d'aménagement de votre cuisine.
                    </p>
                    <p className="mt-8">
                      Ces informations nous aideront à déterminer vos goûts et à
                      vous guider dans la conception de la cuisine de vos rêves.
                    </p>
                    <p className="mt-8">
                      Le questionnaire ne prend que quelques minutes.
                    </p>
                  </div>
                  <div className="text-center">
                    <ButtonComponent
                      loading={loading}
                      type={type}
                      onClick={handleSubmit}
                      btnContent={btnContent}
                      onKeyDown={handleKeyDown}
                      tabIndex="0"
                      icon="fa-solid fa-circle-chevron-right"
                      className="inline-flex items-center py-2 px-3 font-medium text-center text-white bg-[#E44424] rounded-full focus:ring-4 focus:outline-none focus:ring-blue-300 my-4"
                    />
                  </div>
                </section>
              );
          }
        })()}
      </div>
      <div className="flex-1 flex justify-center items-center !max-w-screen-md !w-screen-md">
        <DemoComponent />
      </div>
    </div>
  );
}

export default Questions;
