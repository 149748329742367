import React from "react";
import PropTypes from "prop-types";
import ButtonComponent from "./ButtonComponent";

const JAIME = "J'aime";

const sortResponses = (responses) => {
  return Object.keys(responses).map((key) => {
    return responses[key].sort((a, b) => {
      if (a.description === JAIME) return 1;
      if (b.description === JAIME) return -1;
      return 0;
    });
  });
};

const StandardComponent = ({ reponses, handleAnswers, handleSubmit }) => {
  const sortedResponses = sortResponses(reponses);

  const handleClick = (e, dataItem) => {
    e.preventDefault();
    handleAnswers(e, dataItem);
    handleSubmit();
  };
  return (
    <section className="content pt-20 flex">
      <form onSubmit={handleSubmit}>
        {sortedResponses.map((group, index) => (
          <div key={index} className="flex pb-8 mt-12">
            {group.map((dataItem) => {
              const { id, description } = dataItem;
              const icon =
                description === JAIME
                  ? "fa-solid fa-thumbs-up"
                  : "fa-solid fa-thumbs-down";

              return (
                <div key={id} id="buttons" className="px-16 py-6 relative">
                  <input
                    onChange={(e) => handleAnswers(e, dataItem)}
                    className="hidden"
                    name="rGroup"
                    type="radio"
                    value={description}
                    id={`r${id}`}
                  />

                  <ButtonComponent
                    onClick={(e) => handleClick(e, dataItem)}
                    className="cursor-pointer text-5xl shadow-xl bg-[#E44424] rounded-full text-white w-24 h-24 absolute bottom-0 right-4"
                    ariaLabel={description}
                    icon={icon}
                  />
                </div>
              );
            })}
          </div>
        ))}
      </form>
    </section>
  );
};

StandardComponent.propTypes = {
  reponses: PropTypes.array.isRequired,
  handleAnswers: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default StandardComponent;
