import logo from "../../images/ubni-logo.png";

const DemoComponent = () => {
  return (
    <section className="px-12 content flex flex-col bg-[#13213C] ml-8 rounded-large text-xl pt-8 max-h-[900px] h-[900px]">
      <p className="text-white">
        Ceci est une démo du projet “Algorientation” qui à pour but de mettre à
        disposition un outil de détermination de préférences.
      </p>
      <p className="text-white mt-8">
        {" "}
        Déterminez les préférences de vos clients pour : Mieux les connaitre
        pour mieux les conseillers. Leur proposer vos produits et ainsi les
        faire “entrer” et les “capter” dans votre univers.
      </p>
      <p className="text-white mt-8">
        {" "}
        Ceci est un démo et non une version finale.
      </p>
      <p className="text-white mt-8">
        {" "}
        Le but de la version finale sera d’avoir un back-office vous permettant
        de piloter les questions et les résultats facilement avec plusieurs
        types de question.
      </p>
      <p className="text-white mt-8">
        {" "}
        Derrière vous pourrez intégrer ce questionnaire sur votre site au
        travers, au choix :{" "}
      </p>
      <ul className="list-disc ml-16 text-white">
        <li>d’une API</li>
        <li>d’une iFrame personnalisable</li>
      </ul>
      <p className="text-white mt-8">
        <b>
          Pour le moment, les résultats sont en dur, l'algorithme de
          détermination est en cours de finalisation.
        </b>
      </p>
      <img
        src={logo}
        alt="ubni-logo"
        draggable="false"
        width={"200px"}
        className="mx-auto"
      />
    </section>
  );
};

export default DemoComponent;
