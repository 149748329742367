import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../images/oskab-logo.svg";

function QuestionHeaderComponent({ question, imageURL, icon, iconclasscame }) {
  return (
    <section>
      <img
        src={logo}
        alt="oskab-logo"
        draggable="false"
        width={"200px"}
        className="mx-auto pt-4"
        icon={icon}
        iconclasscame={iconclasscame}
      />
      <div className="flex justify-center">
        <div className="flex shadow-xl rounded-xl my-3 mb-12">
          <div className="flex items-center h-[100%] text-4xl rounded-l-xl bg-[#E44424]">
            <FontAwesomeIcon icon={icon} className={iconclasscame} />
          </div>
          <div className="flex-col justify-center mx-auto">
            <div className="bg-[#F1F0EC] md:text-xl lg:text-3xl leading-12 font-medium rounded-r-xl p-6 mx-auto text-[#E44424]">
              {question}
            </div>
          </div>
        </div>
      </div>
      {imageURL ? (
        <div className="flex justify-center">
          <img
            src={imageURL}
            alt="kitchen"
            draggable="false"
            width={"400px"}
            className="rounded-xl mt-16"
          />
        </div>
      ) : null}
    </section>
  );
}

export default QuestionHeaderComponent;
